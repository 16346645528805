import { CartItem } from "./carts";
import { INVOICE_STATUSES } from "./invoices";
import { CURRENCIES, DeliveryArea, StoreInterface } from "./store";

export interface Customer {
  name?: string;
  email?: string;
  phone?: string;
  area?: string;
  address?: string;
  deliveryContact?: { name?: string; phone?: string };
}
export interface CustomerInterface {
  id: string;
  name: string;
  email?: string;
  phone: string;
  total_orders?: number;
  total_order_amount?: number;
  delivery_address?: string;
  store?: string;
}

export interface OrderInvoice {
  id: string;
  invoice_id: string;
  status: INVOICE_STATUSES;
  payments_enabled?: boolean;
}

export interface OrderInterface {
  id: string;
  customer: CustomerInterface;
  store: string | StoreInterface;
  status: OrderStatusType;
  total_amount: number;
  discount_amount?: number;
  delivery_info?: {
    delivery_address: string;
    name?: string;
    phone?: string;
    delivery_area?: DeliveryArea;
  };
  delivery_method: DELIVERY_METHODS;
  items: CartItem[];
  created_at: string;
  timeline: { id: string; status: OrderStatusType; time: string }[];
  fees: { id: string; label: string; amount: number; type: string }[];
  invoice?: string | OrderInvoice;
  receipt?: string;
  coupon_code?: string;
  currency: CURRENCIES;
  order_notes?: string;
  is_from_seller?: boolean;
  is_paid?:boolean;
  extra_details?: Record<string, string>;
}

export enum ORDER_STATUS {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  FULFILLED = "FULFILLED",
  CANCELLED = "CANCELLED",
  PAYMENT_RECEIVED = "PAYMENT_RECEIVED",
}

export enum ORDER_FEES {
  DELIVERY = "DELIVERY",
  VAT = "VAT",
  OTHER = "OTHER",
  DISCOUNT = "DISCOUNT",
  COUPON = "COUPON",
}

export enum DELIVERY_METHODS {
  PICKUP = "PICKUP",
  DELIVERY = "DELIVERY",
  NONE = 'NONE'
}

export type OrderStatusType = "PENDING" | "PROCESSING" | "FULFILLED" | "CANCELLED" | "PAYMENT_RECEIVED";
